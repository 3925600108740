*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

body,
html {
  height: 100%; }

html {
  font-size: 16px; }

body {
  line-height: 1;
  font-size: 16px;
  font-family: Arial, Helvetica;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: none;
  overflow: hidden; }

body.no-touch {
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0; }

ol li,
ul li {
  list-style: none; }

a {
  text-decoration: none; }

img {
  max-width: 100%; }

#main {
  width: 100%;
  height: 100%; }

.section {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  overflow: hidden;
  display: table; }
  .section:nth-child(1) {
    background: #F8AB37; }
  .section:nth-child(2) {
    background: #F83747; }
  .section:nth-child(3) {
    background: #379FF8; }
  .section:nth-child(4) {
    background: #37F87B; }

.container {
  display: table-cell;
  vertical-align: middle;
  text-align: center; }
  .container a {
    color: #FFF; }

.slide-navigation {
  position: fixed;
  top: 50%;
  bottom: 0;
  right: 32px;
  text-align: center; }
  .slide-navigation > li {
    position: relative;
    display: block;
    margin-bottom: 12px; }
    .slide-navigation > li > a {
      display: block;
      height: 13px;
      width: 13px;
      border: 3px solid #FFF;
      border-radius: 50%;
      background: transparent;
      z-index: 1;
      transition: all 0.4s ease; }
      .slide-navigation > li > a.is-active {
        background: #FFF; }
